import {HttpRequest} from '@angular/common/http';

const BEARER = 'Bearer';

export abstract class BaseAuthInterceptor {
  public addToken<T>(request: HttpRequest<T>, token: string): HttpRequest<T> {
    return request.clone({
      setHeaders: {
        Authorization: `${BEARER} ${token}`
      }
    });
  }
}
