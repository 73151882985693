import {APP_INITIALIZER, FactoryProvider} from '@angular/core';
import {InitializationService} from 'src/app/core/services/initialization.service';

/**
 * Observable returning true is converted as promise.
 * It will subscribe the observable before loading the application.
 * For Example: If we want to fetch data from backend before starting
 * the application we can call the backend endpoint and fetch the response.
 */
function initializeApp(initService: InitializationService): () =>  Promise<boolean> {
  return () => initService.getQueryParams().toPromise();
}

/**
 * Factor provider to be used as provider for app initialization.
 */
export const loadConfigProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  deps: [InitializationService],
  multi: true
};
