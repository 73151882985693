import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'widget',
    loadChildren: () =>
      import('src/app/modules/widget/widget.module').then(
        (m) => m.WidgetModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/modules/portal/portal.module').then(
        (m) => m.PortalModule
      ),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {})],
})
export class AppRoutingModule {}
