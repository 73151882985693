import { HttpBackend } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { TRANSLATIONS, TRANSLATIONS_VERSION } from './translate/constants';
import { initializeTranslations } from './translate';

export function appInitializeFactory(
  _traceService: Sentry.TraceService,
  translateService: TranslateService,
  httpBackend: HttpBackend
) {
  return () => initializeTranslations(
    translateService,
    httpBackend,
    environment.translationsPath,
    TRANSLATIONS_VERSION,
    TRANSLATIONS
  );
}
