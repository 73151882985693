import { JsonProperty } from 'json-object-mapper';

export class UserProperties {
  @JsonProperty({ name: 'sub' }) public sub: string = undefined;

  @JsonProperty({ name: 'custom:customerIds' }) public customerIds: string =
    undefined;

  @JsonProperty({ name: 'email_verified' }) public emailVerified: boolean =
    undefined;

  @JsonProperty({ name: 'custom:authChallenge' }) public authChallenge: string =
    undefined;

  @JsonProperty({ name: 'custom:isSuperAdmin' }) public isAdmin: string =
    undefined;

  @JsonProperty({ name: 'iss' }) public iss: string = undefined;

  @JsonProperty({ name: 'cognito:username' }) public cognitoUsername: string =
    undefined;

  @JsonProperty({ name: 'origin_jti' }) public originJti: string = undefined;

  @JsonProperty({ name: 'aud' }) public aud: string = undefined;

  @JsonProperty({ name: 'event_id' }) public eventId: string = undefined;

  @JsonProperty({ name: 'token_use' }) public tokenUse: string = undefined;

  @JsonProperty({ name: 'auth_time' }) public authTime: number = undefined;

  @JsonProperty({ name: 'exp' }) public exp: number = undefined;

  @JsonProperty({ name: 'iat' }) public iat: number = undefined;

  @JsonProperty({ name: 'jti' }) public jti: string = undefined;

  @JsonProperty({ name: 'email' }) public email: string = undefined;

  getCustomerIds(): string[] {
    try {
      return JSON.parse(this.customerIds);
    } catch (e) {
      return [];
    }
  }
}
