import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {AppModule} from 'app/app.module';
import {setupMixpanelRetry} from 'app/core/http/common/mixpanel-retry-handler';
import {SentryService} from 'app/core/services/sentry.service';
import {environment} from 'src/environments/environment';

if (environment.production) {
  enableProdMode();
  SentryService.init();
}

setupMixpanelRetry();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    console.error('Bootstrap Error:', err);
    if (environment.production) {
      SentryService.handleException(err);
    }
  });
