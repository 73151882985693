import {ErrorHandler, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import * as Sentry from '@sentry/angular';
import {BUService} from 'app/core/services/bu-service';
import {ErrorCacheService} from 'app/core/services/error-cache.service';
import {UserProperties} from 'app/modules/portal/portal-container/models/user-properties.model';
import {ObjectMapper} from 'json-object-mapper';
import {take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import LogRocket from 'logrocket';

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandlerService implements ErrorHandler {
  private buName    = '';
  private buLogo    = '';
  private readonly excludedErrors: string[] = ['Loading chunk', 'Session has expired'];
  private jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient, private buService: BUService, private errorCacheService: ErrorCacheService) {}

  handleError(error: any): void {
    let errorMessage = '';
    console.error(error);

    if (typeof error === 'string') {
      errorMessage = error;
    } else if (error?.message) {
      errorMessage = error.message;
    } else if (error?.error?.message) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.toString();
    }

    // Check if the error message is in the excluded list
    if (this.excludedErrors.some((excludedError) => errorMessage.includes(excludedError))) {
      // Do not capture this error, simply return
      return;
    }

    let userEmail = '';

    try {
      const userProperties = ObjectMapper.deserialize(UserProperties,
        this.jwtHelper.decodeToken(localStorage.getItem(TOKEN_KEY)));
      userEmail            = userProperties.email;
    } catch (error) {
      userEmail = 'Unknown User';
      console.error('Error setting user properties', error);
    }

    // Throttling mechanism using localStorage
    const lastOccurrence = this.errorCacheService.getCache(errorMessage);
    if (lastOccurrence) {
      // Skip sending the notification if the error occurred within the throttle time
      return;
    }

    // Update the error occurrence time in localStorage
    this.errorCacheService.setCache(errorMessage);

    if (environment.production) {
      this.buService.getBUUnit().pipe(take(1)).subscribe(bu => {
        this.buName = bu.name;
        this.buLogo = encodeURI(bu.favicon);
      });

      const eventId = Sentry.captureException(error, {
        user: {email: userEmail ? userEmail : ''}
      });
      const sentryProjectUrl = `https://ws-engineering.sentry.io/issues/?project=${environment.sentryProjectId}&statsPeriod&query=${eventId}`;

      const webhookUrl = `https://chat.googleapis.com/v1/spaces/${environment.googleChatSpace}/messages?key=${environment.googleChatRoomKey}&token=${environment.googleChatRoomToken}`;

      // Get LogRocket session URL and send message to Google Chat
      LogRocket.getSessionURL(sessionURL => {
        // Create a formatted message for Google Chat
        const message = {
          cards: [{
            header: {
              title: `Trilogy Self Serve - ${this.buName} UI Error 🚨`,
              subtitle: `${environment.production ? 'Production' : 'Development'} Environment Alert`,
              imageUrl: this.buLogo, imageStyle: 'AVATAR'
            }, sections: [{
              widgets: [{
                keyValue: {
                  topLabel: 'User experiencing the error', content: userEmail ? userEmail : 'Unknown User',
                  contentMultiline: 'false'
                }
              }, {
                keyValue: {
                  topLabel: 'Error Message', content: errorMessage, contentMultiline: 'true'
                }
              }, {
                keyValue: {
                  topLabel: 'Error Timestamp', content: new Date().toUTCString()
                }
              }, {
                buttons: [{
                  textButton: {
                    text: 'View LogRocket Session', onClick: {
                      openLink: {
                        url: sessionURL
                      }
                    }
                  }
                }]
              }, {
                buttons: [{
                  textButton: {
                    text: 'View in Sentry', onClick: {
                      openLink: {
                        url: sentryProjectUrl
                      }
                    }
                  }
                }]
              }]
            }]
          }]
        };

        this.http.post(webhookUrl, {cards: message.cards}).pipe(take(1)).subscribe({
          error: err => console.error('Failed to send notification', err)
        });
      });
    }
  }
}
