import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AuthInterceptor} from 'src/app/core/http/interceptors/auth.interceptor';
import {PortalAuthInterceptor} from 'src/app/core/http/interceptors/portal-auth.interceptor';
import {AUTH_HTTP_INTERCEPTORS, AuthHttpClient} from 'src/app/core/http/services/auth.http.client';
import {PORTAL_AUTH_HTTP_INTERCEPTORS, PortalAuthHttpClient} from 'src/app/core/http/services/portal-auth.http.client';

@NgModule({
  imports: [], providers: [{
    provide: AUTH_HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
  }, {
    provide: PORTAL_AUTH_HTTP_INTERCEPTORS, useClass: PortalAuthInterceptor, multi: true
  }, AuthHttpClient, PortalAuthHttpClient, provideHttpClient(withInterceptorsFromDi())]
})
export class CustomHttpModule {}
