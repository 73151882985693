import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES, getCurrentLanguage, setCurrentLanguage } from 'app/translate';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent {
  languages = LANGUAGES.map((data) => ({ text: data.nativeShortName.toUpperCase(), value: data.code }));
  selectedLanguage = getCurrentLanguage().code;

  constructor(private translate: TranslateService) {
  }

  switchLanguage(lang: string) {
    setCurrentLanguage(lang, this.translate);
  }

  getLanguageFlagClass(lang: string): string {
    const countryCode = LANGUAGES.find((data) => data.code === lang)!.countryCode;
    return `fi fi-${countryCode.toLowerCase()}`;
  }
}
