import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {takeWhile} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgressStateService {
  private _progressValue        = new BehaviorSubject<number>(20);
  private _currentSentenceIndex = new BehaviorSubject<number>(0);
  private progressSubscription: Subscription;
  private sentenceSubscription: Subscription;

  get progressValue$() {
    return this._progressValue.asObservable();
  }

  public setDefaultProgressAndSentence(value = 20) {
    this._progressValue.next(value);
    this._currentSentenceIndex.next(0);
  }

  get currentSentenceIndex$() {
    return this._currentSentenceIndex.asObservable();
  }

  startProgress(progressUpdateInterval: number, intervalTime: number, totalSentences: number): void {
    // Check if progress is already running and avoid restarting
    if (!this.progressSubscription || this.progressSubscription.closed) {
      this.progressSubscription = interval(progressUpdateInterval)
        // Cap progress at 90%
        .pipe(takeWhile(() => this._progressValue.getValue() < 90))
        .subscribe(() => {
          const currentValue = this._progressValue.getValue();
          this._progressValue.next(currentValue + 1);
        });
    }

    if (!this.sentenceSubscription || this.sentenceSubscription.closed) {
      this.sentenceSubscription = interval(intervalTime)
        .pipe(takeWhile(() => this._progressValue.getValue() < 90))
        .subscribe(() => {
          const currentIndex = this._currentSentenceIndex.getValue();
          this._currentSentenceIndex.next(Math.min(currentIndex + 1, totalSentences - 1));
        });
    }
  }

  reset(): void {
    this._progressValue.next(20);
    this._currentSentenceIndex.next(0);
    this.stopProgress();
  }

  stopProgress(): void {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
    if (this.sentenceSubscription) {
      this.sentenceSubscription.unsubscribe();
    }
  }
}
