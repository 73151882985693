import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {UserProperties} from 'app/modules/portal/portal-container/models/user-properties.model';
import {EditionEnum} from 'app/modules/widget/enums/edition.enum';
import {KontentAIDocumentTypesResponse} from 'app/modules/widget/models/document-type.kontent.model';
import {EditionContent, EditionTableData} from 'app/modules/widget/models/edition-content.model';
import {EditionItem, EditionKontentAIResponse} from 'app/modules/widget/models/edition-kontentai-response.model';
import { IncludedItem } from 'app/modules/widget/models/included-item.model';
import { Item } from 'app/modules/widget/models/item.model';
import {PageSample, PrimeContent} from 'app/modules/widget/models/prime-content.model';
import {PriceContentItem} from 'app/modules/widget/models/price-content.model';
import {KontentPricePage, PricePageItem} from 'app/modules/widget/models/price-kontent.model';
import {PrimeKontentAIResponse} from 'app/modules/widget/models/prime-kontent-response.model';
import {Subscription} from 'app/modules/widget/models/subscription.model';
import {PrimeService, SuccessContentModel} from 'app/modules/widget/models/success-content.model';
import {SuccessItem, SuccessKontentResponse} from 'app/modules/widget/models/success-kontent.model';
import { VerificationContent } from 'app/modules/widget/models/verification-content.model';
import { VerifyInfoResponse } from 'app/modules/widget/models/verify-info-response.model';
import { COUNTRY_LIST } from 'country-list';
import { SubscriptionPlan } from 'app/modules/widget/models/subscription-plan.model';
import Mixpanel from 'mixpanel-browser';
import * as LogRocket from 'logrocket';
import { WelcomeConfigKontent } from '../models/welcome-config-kontent.model';
import {
  CopilotItem,
  CopilotKontentResponse,
} from '../models/copilot-kontent.model';
import { WelcomeConfig } from '../models/welcome-config.model';
import { BU_UNIT_KEY } from '../config/local-storage.config';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class HelperMethods {
  private static readonly HEX_BASE = 16;
  private static readonly US_COUNTRY_CODE = 'US';
  private static readonly US_COUNTRY_NAME = 'United States of America';
  private static readonly RED_GROUP_INDEX = 1;
  private static readonly GREEN_GROUP_INDEX = 2;
  private static readonly BLUE_GROUP_INDEX = 3;
  private static readonly SINGLE_PRODUCT = 1;
  private static readonly TWO_PRODUCTS = 2;
  private static readonly FORCE_RENEWAL_PARAM = 'forceRenewal';
  private static BUTTON_CLICK_EVENT = 'Button Click';
  private static LOGROCKET_ORGANIZATION = 'tsi/trilogy-self-serve';

  /**
   * Converts a hexadecimal color value to an RGB string format. This method parses a hex string and extracts
   * the red, green, and blue color components, converting them to their decimal counterparts.
   *
   * @param {string} hex - The hexadecimal color string to convert. The string should start with '#' and be exactly 6
   *   characters long.
   * @returns {string | null} - Returns a string in the format "R, G, B" if the input is valid, otherwise returns null.
   *
   * @example
   * const hexColor = "#00ff00";
   * const rgbColor = HelperMethods.hexToRgb(hexColor);
   * console.log(rgbColor); // Outputs: "0, 255, 0"
   */

  public static hexToRgb(hex: string): string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `${parseInt(
          result[HelperMethods.RED_GROUP_INDEX],
          HelperMethods.HEX_BASE
        )}, ${parseInt(
          result[HelperMethods.GREEN_GROUP_INDEX],
          HelperMethods.HEX_BASE
        )}, ${parseInt(
          result[HelperMethods.BLUE_GROUP_INDEX],
          HelperMethods.HEX_BASE
        )}`
      : null;
  }

  /**
   * Creates a CSS rgba color value from a given color and opacity. This method supports both hex and RGB color
   * formats.
   * If a hex color is provided, it first converts it to RGB format before appending the specified opacity to create
   * the rgba string.
   *
   * @param {string} color - The base color in hex (e.g., "#ffffff") or RGB (e.g., "255, 255, 255") format.
   * @param {number} opacity - The opacity level for the color, where 0 is fully transparent and 1 is fully opaque.
   * @returns {string} - The resulting CSS rgba color string.
   *
   * @example
   * const color = "#ff0000";
   * const opacity = 0.5;
   * const rgbaColor = HelperMethods.setBackgroundColorWithOpacity(color, opacity);
   * console.log(rgbaColor); // Outputs: "rgba(255, 0, 0, 0.5)"
   */

  public static setBackgroundColorWithOpacity(
    color: string,
    opacity: number
  ): string {
    // If color is in hex format, convert it to rgb
    let rgbColor;
    if (color.includes('#')) {
      rgbColor = HelperMethods.hexToRgb(color);
    } else {
      rgbColor = color;
    }
    return `rgba(${rgbColor}, ${opacity})`;
  }

  /**
   * Generates a formatted string of product names based on the number of products provided. This method handles
   * different cases: a single product name is returned directly, two product names are joined with 'and', and more
   * than two are formatted in a list with commas and 'and' before the last item.
   *
   * @param {Partial<PageSample>[]} products - An array of objects, each containing a `productName` property.
   * @returns {string} - A formatted string listing all product names appropriately based on their count.
   */

  public static getProductNames$(products: Partial<PageSample>[], translate: TranslateService): Observable<string> {
    const productNames = products.map((product) => product.productName);
    if (productNames.length === HelperMethods.SINGLE_PRODUCT) {
      return of(productNames[0]);
    } else if (productNames.length === HelperMethods.TWO_PRODUCTS) {
      return translate.stream('WIDGET.CONFIRMATION.LABELS.AND')
        .pipe(map((and: string) => productNames.join(` ${and} `)));
    } else {
      const lastProductName = productNames.pop();
      return translate.stream('WIDGET.CONFIRMATION.LABELS.AND')
        .pipe(map((and: string) => `${productNames.join(', ')} ${and} ${lastProductName}`));
    }
  }

  /**
   * Retrieves the product tier title for a given product edition from a list of subscription plans.
   * This method searches through the list of subscription plans to find the one that matches the provided product
   * edition, then returns the title associated with that product tier.
   *
   * @param {string} productEdition - The edition of the product for which to find the tier title.
   * @param {SubscriptionPlan[]} subscriptionPlans - An array of subscription plan objects, each containing product
   *   tier details.
   * @returns {string | undefined} - The title of the product tier if found, otherwise undefined.
   */

  public static getProductTierForPresentation(
    productEdition: string,
    subscriptionPlans: SubscriptionPlan[]
  ): string {
    return subscriptionPlans.find((plan) => plan.productTier === productEdition)
      ?.productTierTitle;
  }

  /**
   * Determines if the interests have changed between two arrays. This method compares the length and content of the
   * two interest arrays. It returns true if there are differences in length or any interest present in the current
   * interests array is not found in the previous one.
   *
   * @param {string[]} previousInterests - An array of interests from a previous state.
   * @param {string[]} currentInterests - An array of interests from the current state.
   * @returns {boolean} - Returns true if the interests have changed, otherwise false.
   */

  public static haveInterestsChanged(
    previousInterests: string[],
    currentInterests: string[]
  ): boolean {
    if (previousInterests.length !== currentInterests.length) return true;

    for (const interest of currentInterests) {
      if (!previousInterests.includes(interest)) return true;
    }

    return false;
  }

  /**
   * Extends a list of included items with their descriptions by matching them with a list of items that contain
   * descriptions. This method iterates over the included items, finds the corresponding item in the items list by ID,
   * and adds the description to each included item.
   *
   * @param {IncludedItem[]} includedItems - An array of items to be extended with descriptions.
   * @param {Item[]} items - An array of items containing descriptions, each having an `id` and `desc` property.
   * @returns {Partial<IncludedItem>[]} - A new array of included items with descriptions added, if available.
   */

  public static extendItemsWithDescription(
    includedItems: IncludedItem[],
    items: Item[]
  ): Partial<IncludedItem>[] {
    return includedItems.map((includedItem) => {
      const matchingItem = items.find((item) => item.id === includedItem.id);

      return {
        ...includedItem,
        description: matchingItem?.desc,
      };
    });
  }

  /**
   * Retrieves the country code associated with a given country name from a predefined list of countries.
   * This function performs a case-insensitive search through the list to find a matching country name.
   * If a match is found, it returns the corresponding country code; if no match is found, it returns the original
   * country name.
   *
   * @param {string} countryName - The name of the country for which to find the corresponding country code.
   * @returns {string} - The country code if the country name is found in the list; otherwise, returns the original
   *   country name.
   *
   * @example
   * const countryName = "Canada";
   * const countryCode = HelperMethods.getCountryCodeByCountryName(countryName);
   * console
   .log(countryCode); // Outputs the country code for Canada, assuming it's in the COUNTRY_LIST.
   */

  public static getCountryCodeByCountryName(countryName: string): string {
    const country = COUNTRY_LIST.find(
      (c) => c?.name.toLowerCase() === countryName?.toLowerCase()
    );
    return country ? country.code : countryName;
  }

  /**
   * Determines whether state validation is required based on the given country.
   * This method checks if the specified country matches the U.S. based on various criteria,
   * including country name and country code, using helper methods to resolve these values.
   *
   * @param {string} country - The country name or code to evaluate.
   * @returns {ValidatorFn | null} - Returns the 'Validators.required' if the country is the U.S.,
   * otherwise returns null.
   *
   * @example
   * const country = "USA";
   * const validator = HelperMethods.isStateRequiredOnValueChanges(country);
   * console.log(validator); // Outputs: Validators.required (assuming USA is interpreted as U.S.)
   */

  static isStateRequiredOnValueChanges(country: string): ValidatorFn | null {
    if (!country) return null;
    return country === HelperMethods.US_COUNTRY_NAME ||
      country ===
        HelperMethods.getCountryNameByCode(HelperMethods.US_COUNTRY_CODE) ||
      country ===
        HelperMethods.getCountryCodeByCountryName(HelperMethods.US_COUNTRY_NAME)
      ? Validators.required
      : null;
  }

  /**
   * Determines if a state field is required based on the specified country. This function checks if the provided
   * country matches the United States by comparing against predefined constants and helper method results that resolve
   * both country codes and names. This is useful in forms where country-specific validation rules need to be enforced,
   * such as requiring a state input for users from the United States.
   *
   * @param {string} country - The country name or code to check. This can be an ISO country code or the full country
   *   name.
   * @returns {ValidatorFn | null} - Returns a required validator if the country is identified as the United States,
   * otherwise returns null indicating that state information is not required.
   *
   * @example
   * const country = "US";
   * const validatorFn = HelperMethods.isStateClientRequired(country);
   * console.log(validatorFn); // Outputs: Validators.required, assuming 'US' corresponds to the United States
   */

  static isStateRequired(country: string): ValidatorFn | null {
    if (!country) return null;
    return country === HelperMethods.US_COUNTRY_CODE ||
      country ===
        HelperMethods.getCountryNameByCode(HelperMethods.US_COUNTRY_CODE) ||
      country ===
        HelperMethods.getCountryCodeByCountryName(HelperMethods.US_COUNTRY_NAME)
      ? Validators.required
      : null;
  }

  /**
   * Checks if the state field is required within a form view, based on the value of a country control.
   * This method dynamically determines the necessity of state information by checking the country code
   * against U.S. standards.
   *
   * @param {string} countryControlPath - The form control path for accessing the country value.
   * @param {FormGroup} form - The form group containing the country control.
   * @returns {boolean} - Returns true if the state is required for the given country; otherwise, false.
   *
   * @example
   * const formGroup = new FormGroup({
   *   country: new FormControl('US')
   * });
   * const isRequired = HelperMethods.isStateRequiredInView('country', formGroup);
   * console.log(isRequired); // Outputs: true
   */

  static isStateRequiredInView(
    countryControlPath: string,
    form: FormGroup
  ): boolean {
    const countryControl = form?.get(countryControlPath);
    return (
      countryControl &&
      (countryControl.value === HelperMethods.US_COUNTRY_CODE ||
        HelperMethods.getCountryNameByCode(countryControl.value) ===
          HelperMethods.US_COUNTRY_NAME)
    );
  }

  /**
   * Retrieves the country name corresponding to a given country code from a predefined list.
   * If the country code is not found in the list, returns the country code as a fallback.
   *
   * @param {string} countryCode - The ISO country code to look up.
   * @returns {string} - The name of the country if found; otherwise, returns the provided country code.
   *
   * @example
   * const countryCode = "US";
   * const countryName = HelperMethods.getCountryNameByCode(countryCode);
   * console.log(countryNames); // Outputs: 'United States Of America' assuming the code 'US' maps to 'United States
   * of America' in the country list.
   */

  public static getCountryNameByCode(countryCode: string): string {
    const country = COUNTRY_LIST.find(
      (c) => c?.code.toUpperCase() === countryCode?.toUpperCase()
    );
    return countryCode ? (country ? country.name : countryCode) : '';
  }

  /**
   * Maps verification information from Kontent by Kentico to a structured configuration model suitable for the Verify
   * Info page in the UI. This function extracts and configures the necessary data based on the business unit name
   * stored locally. It ensures that the information displayed is relevant to the specific business unit by filtering
   * the input data.
   *
   * @param {VerifyInfoResponse} verifyInfoResponse - The raw data response from Kontent containing verification
   *   information elements.
   * @returns {VerificationContent} - Returns a fully populated verification content object that includes various
   *   labels, texts, and URLs necessary for rendering the Verify Info page, all tailored to the specific business
   *   unit.
   *
   * @example
   * const verifyInfoData = {
   *   items: [{...}]
   * };
   * const verificationConfig = ClassName.mapKontentInformationToVerifyInfoConfig(verifyInfoData);
   * console.log(verificationConfig); // Outputs structured verification information for the UI.
   *
   * @throws {Error} - Throws an error if the business unit name is not found within the verify info response,
   *   indicating a data mismatch or configuration issue.
   */

  public static mapKontentResponseToVerifyInfoConfig(
    verifyInfoResponse: VerifyInfoResponse
  ) {
    const buname = localStorage.getItem(BU_UNIT_KEY);
    const verifyInfoConfig: VerificationContent = {
      title: '',
      subscriptionInfoLabel: '',
      accountInfoLabel: '',
      accountInfoBanner: '',
      loaderText: '',
      updateAccountInfoLabel: '',
      description: '',
      videoUrl: '',
      overlayImage: '',
      buName: '',
    };

    const filteredItem = verifyInfoResponse.items.find(
      (item: any) =>
        item.elements?.buname?.value?.toLocaleLowerCase() ===
        buname?.toLowerCase()
    );

    if (!filteredItem) {
      // Return null if the filtered item doesn't exist
      return null;
    }

    verifyInfoConfig.title = filteredItem?.elements?.title?.value;
    verifyInfoConfig.subscriptionInfoLabel =
      filteredItem?.elements?.subscriptioninfolabel?.value;
    verifyInfoConfig.accountInfoLabel =
      filteredItem?.elements?.accountinfolabel?.value;
    verifyInfoConfig.accountInfoBanner =
      filteredItem?.elements?.accountinfobanner?.value;
    verifyInfoConfig.loaderText = filteredItem.elements?.loadertext?.value;
    verifyInfoConfig.updateAccountInfoLabel =
      filteredItem?.elements?.updateaccountinfolabel?.value;
    verifyInfoConfig.description = filteredItem.elements?.description?.value;
    verifyInfoConfig.videoUrl = HelperMethods.formatAssetUrl(
      filteredItem?.elements?.videourl?.value
    );
    verifyInfoConfig.overlayImage = HelperMethods.formatAssetUrl(
      filteredItem?.elements?.videooverlayimage?.value
    );
    verifyInfoConfig.buName = buname;

    return verifyInfoConfig;
  }

  /**
   * Maps the welcome configuration data from Kontent by Kentico to a structured format suitable for the UI,
   * integrating data from the Copilot content based on the business unit name stored locally and subscription plan
   * specifics. This function filters both welcome and copilot data according to the business unit and the product's
   * family code and variant (if applicable), then constructs the full configuration object for the welcome page.
   *
   * @param {WelcomeConfigKontent} welcomeConfigResponse - The raw configuration data for the welcome page from
   *   Kontent.
   * @param {CopilotKontentResponse} copilotResponse - The Copilot data from Kontent, containing additional contextual
   *   content like video URLs and labels.
   * @param {SubscriptionPlan} [subscriptionPlan] - The user's subscription plan, which may influence the content
   *   filtering based on product characteristics.
   * @returns {WelcomeConfig} - Returns a comprehensive configuration object that includes all necessary elements for
   *   rendering the welcome page, such as video URLs, texts, overlay images, and interactive elements related to the
   *   Copilot feature.
   *
   * @example
   * const welcomeData = {
   *   items: [{...}],
   *   modular_content: {...}
   * };
   * const copilotData = {
   *   items: [{...}],
   *   modular_content: {...}
   * };
   * const subscriptionPlan = {
   *   product: {
   *     family: { code: 'exampleCode' },
   *     variant: { code: 'exampleVariant' }
   *   }
   * };
   * const welcomeConfig = ClassName.mapKontentResponseToWelcomeConfig(welcomeData, copilotData, subscriptionPlan);
   * console.log(welcomeConfig); // Outputs structured welcome configuration for the UI.
   */

  public static mapKontentResponseToWelcomeConfig(
    welcomeConfigResponse: WelcomeConfigKontent, copilotResponse: CopilotKontentResponse,
    subscriptionPlan?: SubscriptionPlan
  ): WelcomeConfig {

    if (!welcomeConfigResponse || !copilotResponse) {
      // Return null if the responses are missing
      return null;
    }
    const filterProductByCode    = (item: CopilotItem) => item.elements.product_name.value ===
      subscriptionPlan.product.family.code;
    const filterProductByVariant = (item: CopilotItem) => item.elements.product_variant_code.value ?
                                                          item.elements.product_variant_code.value ===
                                                            subscriptionPlan.product.variant.code : true;
    const buName = localStorage.getItem(BU_UNIT_KEY);
    const welcomeConfig: WelcomeConfig = {
      priceResetVideoUrl: '',
      singleProductVideoUrl: '',
      multiProductVideoUrl: '',
      buName: '',
      content: '',
      overlayImage: '',
      ai: [],
    };

    const filteredItem = welcomeConfigResponse?.items.find(
      (item) =>
        item.elements?.buname?.value?.toLocaleLowerCase() ===
        buName?.toLowerCase()
    );

    if (!filteredItem) {
      return null;
    }

    // Map direct fields from the filtered item based on buName
    welcomeConfig.priceResetVideoUrl = HelperMethods.formatAssetUrl(
      filteredItem.elements?.priceresetvideourl?.value
    );
    welcomeConfig.singleProductVideoUrl = HelperMethods.formatAssetUrl(
      filteredItem.elements?.singleproductvideourl?.value
    );
    welcomeConfig.multiProductVideoUrl = HelperMethods.formatAssetUrl(
      filteredItem.elements?.multiproductvideourl?.value
    );
    welcomeConfig.overlayImage = HelperMethods.formatAssetUrl(
      filteredItem.elements?.overlayimage?.value
    );
    welcomeConfig.content = filteredItem.elements?.welcome_text?.value;
    welcomeConfig.buName = buName;

    // Handle the copilot response
    welcomeConfig.ai = copilotResponse?.items
      .filter(item => filterProductByCode(item) && filterProductByVariant(item))
      .map((item: CopilotItem) => {
        const hasCopilot = item.elements?.hascopilot?.value;

        if (!hasCopilot) {
          // Skip items that don't have hasCopilot
          return null;
        }

        return {
          name: item.elements?.product_name?.value, productVariantCode: item.elements?.product_variant_code?.value,
          label: item.elements?.label?.value, videoUrl: HelperMethods.formatAssetUrl(item.elements?.videourl?.value),
          overlayImage: HelperMethods.formatAssetUrl(item.elements?.overlayimage?.value),
          content: item.elements?.copilot_content?.value, hasCopilot,
          featheryFormName: item.elements?.featheryformname?.value,
          featheryFormKey: item.elements?.featheryformkey?.value, featheryFormId: item.elements?.featheryformid?.value,
          useCases: item.elements?.usecases?.value.map((useCaseCodename: string) => {
            const useCase = copilotResponse.modular_content[useCaseCodename];
            return {
              title: useCase.elements?.title?.value,
              notificationDescription: useCase.elements?.notificationdescription?.value,
              items: useCase.elements?.items?.value.map((itemCodename: string) => {
                const item = copilotResponse?.modular_content[itemCodename];
                return {
                  title: item.elements?.title?.value, description: item.elements?.listitems?.value
                };
              }),
            };
          }),
        };
      }).filter(Boolean); // Filter out null values from invalid copilot items

    if (welcomeConfig.ai.length === 0) {
      // Return null if no valid copilot items were found
      return null;
    }

    return welcomeConfig;
  }

  /**
   * Maps a success page response from Kontent AI to a structured model suitable for UI rendering.
   * This function filters response items based on product codes and variants associated with the subscription plan,
   * then constructs an array of `PrimeService` objects each representing key data for display on a success page.
   *
   * @param {SuccessKontentResponse} data - The full response from Kontent AI, including items and modular content.
   * @param {SubscriptionPlan} subscriptionPlan - The subscription plan used to filter relevant success items based on
   *   product and variant codes.
   * @returns {SuccessContentModel} - Returns a model containing an array of `PrimeService` objects, each structured to
   *   provide detailed information necessary for rendering success elements on the UI.
   *
   * @example
   * const kontentResponse = {
   *   items: [{...}],
   *   modular_content: {...}
   * };
   * const subscriptionPlan = {
   *   product: {
   *     family: { code: 'exampleCode' },
   *   variant: { code: 'exampleVariant' }
   * }
   * };
   * const successConfig = ClassName.mapKontentResponseToSuccessConfig(kontentResponse, subscriptionPlan);
   * console.log(successConfig); // Outputs structured success content for the UI.
   */

  public static mapKontentResponseToSuccessConfig(data: SuccessKontentResponse,
    subscriptionPlan: SubscriptionPlan): SuccessContentModel | null {
    if (!data?.items || data.items.length === 0) {
      return null;
    }
    const filterProductByCode    = (item: SuccessItem) => item.elements.product_code.value ===
      subscriptionPlan.product.family.code;
    const filterProductByVariant = (item: SuccessItem) => item.elements.product_variant_code.value ?
                                                          item.elements.product_variant_code.value ===
                                                            subscriptionPlan.product.variant.code : true;
    const modularContent = data.modular_content;

    // Create a list of PrimeService objects, one for each item in the response
    const platinumService: PrimeService[] = data.items
      .filter((item: SuccessItem) => filterProductByCode(item) && filterProductByVariant(item)).map(
        (item: SuccessItem) => {
      return {
        name: item?.elements?.product_code?.value, variantCode: item?.elements?.product_variant_code?.value,
        skipSuccess: item.elements.skip_success.value.toLowerCase() === 'yes',
        videoUrl: HelperMethods.formatAssetUrl(item.elements.video_url.value),
        overlayImage: HelperMethods.formatAssetUrl(item.elements.overlay_image.value),
        description: item.elements.description.value,
        services: item.elements.services.value.map((serviceCode: string) => {
          const serviceData = modularContent[serviceCode];
          return {
            title: serviceData.elements.title.value, items: [{
              title: serviceData.elements.title.value, description: serviceData.elements.listitems.value,
              tooltipContent: serviceData.elements.tooltip_content ?
                              this.normalizeRichText(serviceData.elements.tooltip_content.value) : null
            }]
          };
        })
      };
    });

    return platinumService.length > 0 ? {primeServices: platinumService} : null;
  }

  /**
   * Maps data from the Kontent AI response to the EditionContent model for the Product Edition page.
   * This method filters the input data based on the provided product and variant codes, then constructs an array of
   * structured objects that include detailed product tier, video, description, and tabular data, all of which are
   * tailored for display.
   *
   * @param {EditionKontentAIResponse} editionKontentResponse - The complete response data from Kontent AI, containing
   *   both items and modular content.
   * @param {string} variantCode - The product variant code used to further refine the filtering of data items. Items
   *   with a matching variant code are prioritized.
   * @param {string} productCode - The product code used to filter data items. Only items with matching product codes
   *   are processed.
   * @returns {EditionContent[]} - An array of EditionContent objects, each representing the product information
   *   formatted for the Edition page. This includes multimedia elements, product tiers, and detailed feature
   *   descriptions.
   *
   * @example
   * const responseFromKontentAI = {
   *   items: [{...}],
   *   modular_content: {...}
   * };
   * const productCode = 'exampleCode';
   * const variantCode = 'variant123';
   * const editionContents = ClassName.mapEditionKontentResponse(responseFromKontentAI, variantCode, productCode);
   * console.log(editionContents); // Outputs formatted product edition content for UI rendering.
   */

  public static mapEditionKontentResponse(editionKontentResponse: EditionKontentAIResponse, variantCode: string,
    productCode: string): EditionContent[] {
    if (!editionKontentResponse?.items || !editionKontentResponse?.modular_content) {
      return [];
    }
    const filterByProductCode = (item: EditionItem) => item.elements.product_code.value === productCode;
    const filterByVariantCode = (item: EditionItem) => {
      // Check if there is a variant code on the item and if it's equal to the provided variantCode.
      // If variantCode is not provided, the filter passes automatically.
      return item.elements.product_variant_code.value ? item.elements.product_variant_code?.value === variantCode :
             true;
    };

    const mapProductTiers = (tierCodename: string, modularContent: any): EditionEnum => {
      const tierItem = modularContent[tierCodename];
      return tierItem?.elements?.product_tier.value as EditionEnum;
    };

    const extractTableData = (codename: string, modularContent: any): EditionTableData => {
      const contentItem = modularContent[codename];
      const element = contentItem?.elements || {};

      return {
        feature: element.feature_row_title_cc491ff?.value || '', basic: element.standard_included?.value || '',
        contentIncluded: element.professional_included?.value || '',
        engageIncluded: element.enterprise_included?.value || '', tooltip: element.tooltip_content?.value || ''
      };
    };

    const extractHeaderRow = (firstRow: any): Partial<EditionTableData> => {
      const headerMapping: { [key: string]: keyof EditionTableData } = {
        standard_column_header: 'basicContent', professional_column_header: 'content',
        enterprise_column_header: 'engage', feature_column_header: 'featureTitle'
      };

      const headerRow: Partial<EditionTableData> = {} as Partial<EditionTableData>;

      Object.entries(headerMapping).forEach(([key, value]) => {
        if (firstRow[key]?.value) {
          headerRow[value] = firstRow[key].value;
        }
      });

      return headerRow;
    };

    return editionKontentResponse?.items
      .filter(item => filterByProductCode(item) && filterByVariantCode(item))
      .map((item: any) => {
        const elements       = item.elements;
        const modularContent = editionKontentResponse.modular_content;

        // Initialize default return structure
        const result = {
          name: elements.product_code.value || '', title: elements.page_title.value || '',
          productTiers: elements.product_tiers.value.map(
            (tierCodename: string) => mapProductTiers(tierCodename, modularContent)) || [],
          videoUrl: HelperMethods.formatAssetUrl(elements.video_url.value) || '',
          description: elements.description.value || '',
          overlayImage: HelperMethods.formatAssetUrl(elements.video_overlay_image.value) || '',
          tableData: [] as EditionTableData[]
        };

        // Only proceed if table_data exists and has elements
        if (elements.table_data?.value?.length) {
          // Extract the first row to set headers
          const firstRowElements = modularContent[elements.table_data.value[0]]?.elements;
          if (firstRowElements) {
            const headerRow = extractHeaderRow(firstRowElements);

            const featureFirstRow: EditionTableData = {
              feature: firstRowElements.feature_row_title_cc491ff?.value || '',
              basic: firstRowElements.standard_included?.value || '',
              contentIncluded: firstRowElements.professional_included?.value || '',
              engageIncluded: firstRowElements.enterprise_included?.value || '',
              tooltip: this.normalizeRichText(firstRowElements.tooltip_content?.value)
            };

            // Extract remaining table data without headers
            const tableData = elements.table_data.value.slice(1).map(
              (codename: string) => extractTableData(codename, modularContent));

            result.tableData = [headerRow as EditionTableData, featureFirstRow, ...tableData];
          }
        }

        return result;
      });
  }

  /**
   * Maps the response from Kontent AI to the PrimeContent model used in the UI.
   *
   * @param {any} kontentData - The raw data received from Kontent AI.
   * @returns {PrimeContent} The mapped PrimeContent object that matches the UI model.
   */

  public static mapPrimeKontentResponse(kontentData: PrimeKontentAIResponse): PrimeContent {
    if (!kontentData?.items || !kontentData?.modular_content) {
      return {genericPage: []};
    }
    // Map the Prime products from Kontent AI data
    let primeProductsFromKontent = kontentData.items.map((item) => {
      const pageSamples: PageSample[] = item.elements.prime_products.value.map((productCodename: string) => {
        const product            = kontentData.modular_content[productCodename];
        const featureGridContent = kontentData.modular_content[product.elements.prime_product_content.value[0]];

        return {
          productName: product.elements.product_name.value, title: product.elements.title.value,
          titleIcon: product.elements.title_font_awesome_icon.value,
          productColor: product.elements.product_theme_color_hex.value, featureGrid: {
            logo: HelperMethods.formatAssetUrl(featureGridContent.elements.prime_product_grid_logo.value),
            title: featureGridContent.elements.prime_product_grid_title.value,
            subtitle: featureGridContent.elements?.footer?.value,
            items: featureGridContent.elements.feature_grid_items.value.map((featureCodename: string) => {
              const feature = kontentData.modular_content[featureCodename];
              return {
                title: feature.elements.feature_grid_item_title.value,
                description: feature.elements.feature_grid_item_description.value,
                icon: feature.elements.feature_grid_item_fontawesome_icon.value
              };
            })
          }, videoUrl: HelperMethods.formatAssetUrl(product.elements.video_url.value),
          overlayImage: HelperMethods.formatAssetUrl(product.elements.video_overlay_image.value)
        };
      });

      return {
        title: item.elements.title.value, description: item.elements.description.value,
        videoUrl: HelperMethods.formatAssetUrl(item.elements.video_url.value),
        overlayImage: HelperMethods.formatAssetUrl(item.elements.video_overlay_image.value),
        buName: item.elements.buname.value, content: {pageSamples}
      };
    });

    return {
      genericPage: primeProductsFromKontent
    };
  }

  /**
   * Maps the Kontent AI response to a PriceContentItem.
   *
   * @param {KontentPricePage} kontentData - The raw data from Kontent AI.
   * @returns {PriceContentItem | null} The mapped PriceContentItem if the BU name from storage matches an item in
   *   kontentData, otherwise null.
   */
  public static mapPriceKontentResponse(kontentData: KontentPricePage): PriceContentItem | null {
    const buNameFromStorage = localStorage.getItem(BU_UNIT_KEY);

    if (!buNameFromStorage) {
      return null;
    }

    const filteredItems = kontentData?.items?.filter((item: any) => {
      return item?.elements?.bu_name?.value?.toLowerCase() === buNameFromStorage.toLowerCase();
    }) || [];

    if (filteredItems.length === 0) {
      return null;
    }

    const content = filteredItems.map((item: PricePageItem) => ({
      priceIncreaseVideoUrl: HelperMethods.formatAssetUrl(item?.elements?.price_increase_video_url?.value) || '',
      priceResetVideoUrl: HelperMethods.formatAssetUrl(item?.elements?.price_reset_video_url?.value) || '',
      overlayImage: HelperMethods.formatAssetUrl(item?.elements?.video_overlay_image?.value) || '',
      buName: item.elements?.bu_name?.value || '', description: item?.elements?.description?.value || '',
      priceResetDescription: item?.elements?.price_reset_description?.value || ''
    }));

    return content[0] || null;
  };

  /**
   * Maps the Kontent AI document types response to an array of objects with `text` and `value` properties.
   *
   * @param {KontentAIDocumentTypesResponse} kontentData - The response data from Kontent AI containing document types
   *   information.
   * @param {TranslateService} translate - The TranslateService instance for translating the document type names.
   * @returns {Array<{text: string, value: string}>} - An array of objects each containing the document type name and
   *   value.
   */
  public static mapDocumentTypesKontentAiResponse$(kontentData: KontentAIDocumentTypesResponse, translate: TranslateService) {
    if (!kontentData?.items?.[0]?.elements?.document_types?.value || !kontentData?.modular_content) {
      return of([]);
    }

    const documentTypes  = kontentData.items[0].elements.document_types.value;
    const modularContent = kontentData.modular_content;

    return combineLatest(documentTypes.map((docTypeCodename: string) => {
      const docType = modularContent[docTypeCodename];
      let name = docType?.system?.name;
      if (name) {
        name = name.toUpperCase().replace(/ /g, '_');
      }
      return translate.stream(`SHARED.DOCUMENT_TYPES.${name || 'UNKNOWN'}`)
        .pipe(
          map(translatedName => ({
            text: translatedName,
            value: docType?.elements?.type?.value || 'Unknown'
          }))
        );
    }));
  }

  /**
   * Normalizes rich text input from Kontent AI by handling specific HTML patterns that indicate an empty field.
   * This method checks for the common empty HTML pattern used by Kontent AI ('<p><br></p>'), which might be returned
   * even when a text field is actually empty. This ensures that the application treats these fields as null,
   * simplifying further processing and display logic in the UI.
   *
   * @param {string} input - The raw HTML string received from a rich text field in Kontent AI.
   * @returns {string | null} - Returns the original input if it contains meaningful content; otherwise, returns null
   *   if the input matches the empty HTML pattern.
   *
   * @example
   * const rawRichText = '<p><br></p>';
   * const normalizedText = ClassName.normalizeRichText(rawRichText);
   * console.log(normalizedText); // Outputs 'null', indicating the field is effectively empty.
   */

  private static normalizeRichText(input: string): string | null {
    // Check if the input is exactly '<p><br></p>', and return null if true
    if (input.trim() === '<p><br></p>') {
      return null;
    }
    return input;
  }

  /**
   * Formats a given asset URL to include the full path if necessary. This method is essential for handling asset URLs
   * that are input as relative paths (e.g., starting with '/assets'). It prepends the environment's S3 bucket URL
   * to relative paths to convert them into absolute URLs, suitable for use in environments where full URLs are needed
   * (such as in web applications hosted on different domains).
   *
   * @param {string} assetUrl - The URL of the asset, which may be a relative or absolute path.
   * @returns {string} - The fully qualified URL. If the input is already an absolute URL, it returns the input
   *   unchanged; otherwise, it prepends the environment's S3 bucket URL to the relative URL.
   *
   * @example
   * const partialUrl = '/assets/images/logo.png';
   * const fullUrl = ClassName.formatAssetUrl(partialUrl);
   * console.log(fullUrl); // Outputs: 'https://s3.example.com/assets/images/logo.png', assuming the S3 bucket URL is
   *   'https://s3.example.com'
   */

  public static formatAssetUrl(assetUrl: string): string {
    if (!assetUrl.startsWith('/assets')) {
      return assetUrl;
    } else {
      const S3BucketUrl = environment.s3BucketURL;
      return `${S3BucketUrl}${assetUrl}`;
    }
  }

  /**
   * Checks if the user has admin privileges based on the user properties.
   *
   * @param {UserProperties} userProperties - The properties of the user.
   * @returns {boolean} True if the user is an admin, otherwise false.
   */
  public static isUserAdmin(userProperties: UserProperties): boolean {
    return JSON.parse(userProperties.isAdmin);
  }

  /**
   * Generates a cache key for preview data based on provided parameters.
   *
   * @param {string} productEdition - The edition of the product.
   * @param {string} successPlan - The success plan associated with the product.
   * @param {any} bodyRequest - The request body containing additional parameters.
   * @param {Subscription} subscription - The subscription object.
   * @param {Subscription} parentSubscription - The parent subscription object.
   * @returns {string} A string representing the cache key.
   */

  public static generatePreviewCacheKey(productEdition: string, successPlan: string, bodyRequest: any,
    subscription: Subscription, parentSubscription: Subscription): string {
    return `${productEdition}-${successPlan}-${bodyRequest.frequency}-${bodyRequest.duration}-${subscription?.id}-${parentSubscription?.id}`;
  }

  /**
   * Checks if the 'adminRenew' parameter is present and set to 'true' in the parent window's URL.
   * This method is useful for scenarios where the application is running inside an iframe and
   * the parameter needs to be retrieved from the parent URL.
   *
   * @returns {boolean | undefined} Returns `true` if the 'adminRenew' parameter is present and set to 'true';
   *                                Returns `undefined` if the parameter is not present or an error occurs.
   */
  public static checkAdminRenewParam() {
    if (window.parent) {
      try {
        const parentUrl    = new URL(window.parent.location.href);
        const parentParams = new URLSearchParams(parentUrl.search);
        return parentParams.get(HelperMethods.FORCE_RENEWAL_PARAM) === 'true';
      } catch (error) {
        console.error('Error accessing parent URL parameters', error);
      }
    }
  }

  public static initMixpanelForAuthFlow(email: string, buttonId: string): void {
    Mixpanel.track(HelperMethods.BUTTON_CLICK_EVENT, {
      $user_id: email, buttonId: buttonId, $current_url: window.location.href
    });
  }

  public static initLogRocket(email: string) {
    if (environment.production) {
      LogRocket.init(HelperMethods.LOGROCKET_ORGANIZATION, {
        mergeIframes: true
      });
      LogRocket.identify(email, {
        email: email, name: email
      });
    }
  }
}
