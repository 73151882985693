import { JsonProperty } from 'json-object-mapper';

export class SignInResponse {
  @JsonProperty({ name: 'AccessToken' }) public accessToken: string = undefined;

  @JsonProperty({ name: 'ExpiresIn' }) public expiresIn: number = undefined;

  @JsonProperty({ name: 'IdToken' }) public idToken: string = undefined;

  @JsonProperty({ name: 'TokenType' }) public tokenType: string = undefined;

  @JsonProperty({ name: 'RefreshToken' }) public refreshToken: string =
    undefined;
}
