import { HttpBackend } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CURRENT_LANGUAGE_STORAGE_KEY, LANGUAGE_CHANGED_EVENT_TYPE, LANGUAGES, LOCALES_MAP } from '../constants';
import { Language } from '../enums';
import { LanguageData, TranslationResource } from '../models';
import { MultiTranslateHttpLoader } from '../services';

interface TranslationsLoadResult {
  code: string;
  values: Object;
}

export function getLanguageData(language: string): LanguageData | undefined {
  return LANGUAGES.find((item: LanguageData) => item.code === language || item.locale === language);
}

export function getCurrentLanguage(): LanguageData {
  let lang = getLanguageFromLocalStorage() || getBrowserLanguage() || Language.English;

  if (!isValidLanguage(lang)) {
    lang = Language.English;
  }

  return getLanguageData(lang) as LanguageData;
}

function getLanguageFromLocalStorage(): string | null {
  const lang = localStorage.getItem(CURRENT_LANGUAGE_STORAGE_KEY);
  return lang && lang !== '""' ? lang.replace(/"/g, '') : null;
}

function getBrowserLanguage(): string | null {
  if (typeof window !== 'undefined' && navigator) {
    const browserLang = navigator.languages?.[0] || navigator.language;
    return browserLang ? parseLanguageCode(browserLang) : null;
  }
  return null;
}

function parseLanguageCode(langCode: string): string {
  return langCode.split(/[-_]/)[0];
}

function isValidLanguage(lang: string): boolean {
  return Object.values(Language).includes(lang as Language);
}

export function setCurrentLanguage(language: string, translateService: TranslateService): void {
  localStorage.setItem(CURRENT_LANGUAGE_STORAGE_KEY, language);
  if (translateService.currentLang !== language) {
    translateService.use(language);
  }
}

export async function initializeTranslations(
  translateService: TranslateService,
  httpBackend: HttpBackend,
  translationsPath: string,
  version: string,
  translations: TranslationResource[],
): Promise<Language> {
  const language: Language = initializeLanguage(translateService);
  await loadAndApplyTranslations(translateService, httpBackend, translationsPath, version, translations);
  translateService.use(language);
  translateService.onLangChange.subscribe((event: LangChangeEvent) => {
    const iframe: HTMLIFrameElement | null = document.getElementById('tsi-portal-widget') as HTMLIFrameElement;
    if (!iframe) {
      return;
    }
    iframe.contentWindow.postMessage({
      type: LANGUAGE_CHANGED_EVENT_TYPE,
      payload: {
        lang: event.lang
      }
    }, '*');
  });
  window.addEventListener('message', (event: MessageEvent) => {
    if (event.data.type === LANGUAGE_CHANGED_EVENT_TYPE) {
      setCurrentLanguage(event.data.payload.lang, translateService);
    }
  });
  return language;
}

function initializeLanguage(translateService: TranslateService): Language {
  let languageData: LanguageData = getCurrentLanguage();
  let language: Language = languageData.code as Language;
  if (!LOCALES_MAP.has(language)) {
    languageData = getLanguageData(Language.English) as LanguageData;
    language = languageData.code as Language;
  }
  translateService.setDefaultLang(language);
  translateService.addLangs(LANGUAGES.map((item: LanguageData) => item.code));
  return language;
}

async function loadAndApplyTranslations(
  translateService: TranslateService,
  httpBackend: HttpBackend,
  translationsPath: string,
  version: string,
  translations: TranslationResource[],
): Promise<void> {
  const promises: Array<Promise<TranslationsLoadResult>> = [];
  LANGUAGES.forEach((data: LanguageData) => {
    const resources: TranslationResource[] = translations.map(
      (item: TranslationResource) => createResource(data, item, translationsPath, version)
    );
    const translateLoader = new MultiTranslateHttpLoader(httpBackend, resources);
    promises.push(
      translateLoader.getTranslation(data.code).toPromise().then(
        (values: Object) =>
          ({ code: data.code, values }) as TranslationsLoadResult,
      ),
    );
  });

  const results: Array<{ status: string; value?: TranslationsLoadResult; reason?: unknown }> = [];
  await Promise.all(promises.map(p => p.then(
    value => results.push({ status: 'fulfilled', value }),
    reason => results.push({ status: 'rejected', reason })
  )));

  results.forEach((result: { status: string; value?: TranslationsLoadResult; reason?: unknown }) => {
    if (result.status === 'fulfilled' && result.value) {
      translateService.setTranslation(result.value.code, result.value.values, false);
    }
  });
}

function createResource(
  data: LanguageData,
  item: TranslationResource,
  translationsPath: string,
  version: string
): TranslationResource {
  const prefix = `${translationsPath}${item.prefix.replace('{{lang}}', data.code)}`;
  const suffix = `${item.suffix}?v${version}`;
  return { ...item, prefix, suffix };
}
