import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAuthInterceptor } from 'src/app/core/http/interceptors/base-auth.interceptor';
import { InitializationService } from 'src/app/core/services/initialization.service';

@Injectable()
export class AuthInterceptor extends BaseAuthInterceptor implements HttpInterceptor {
  constructor(private readonly initService: InitializationService) { super(); }

  /**  Intercept http calls to use Authorization header for each endpoint call. */
  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // Example condition to skip adding the token for Kontent AI requests
    // You might need to adjust the condition to fit your URL structure or criteria
    if (!request.url.includes('deliver.kontent.ai')) {
      request = this.addToken<T>(request, this.initService.token);
    }

    return next.handle(request);
  }
}
