export function setupMixpanelRetry() {
  (function() {
    const originalOpen: any = XMLHttpRequest.prototype.open;
    const originalSend: any = XMLHttpRequest.prototype.send;

    XMLHttpRequest.prototype.open = function(_: string, url: string) {
      (this as any)._isMixpanelRequest = url.includes('https://api-js.mixpanel.com/track/');
      (this as any)._requestUrl        = url;
      return originalOpen.apply(this, arguments);
    };

    XMLHttpRequest.prototype.send = function(body: any) {
      // Check if the request is a Mixpanel track request
      if ((this as any)._isMixpanelRequest && !(this as any)._retrying) {
        const handleResponse = () => {
          if (this.status !== 200 && !(this as any)._retrying) {
            console.error(`Mixpanel track failed with status ${this.status}. Retrying...`);
            retryMixpanelRequest((this as any)._requestUrl, body, 2);
          }
        };

        this.addEventListener('load', handleResponse);
        this.addEventListener('error', handleResponse);
        this.addEventListener('abort', handleResponse);
      }

      return originalSend.apply(this, arguments);
    };

    function retryMixpanelRequest(url: any, body: any, retries: any) {
      if (retries > 0) {
        setTimeout(() => {
          let xhr = new XMLHttpRequest();
          xhr.open('POST', url, true);
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          (xhr as any)._retrying = true; // Set a flag to indicate this is a retry request

          xhr.onload = xhr.onerror = function() {
            if (xhr.status !== 200) {
              console.warn(`Retrying Mixpanel request... attempts remaining: ${retries - 1}`);
              retryMixpanelRequest(url, body, retries - 1);
            }
          };

          xhr.send(body);
          // Increase delay with each retry to avoid rapid retry bursts
        }, 1000 * (3 - retries));
      } else {
        console.error('Mixpanel event tracking failed after maximum retries.');
      }
    }
  })();
}
