import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import enKendoLocale from '@progress/kendo-angular-intl/locales/en/all';
import deKendoLocale from '@progress/kendo-angular-intl/locales/de/all';
import { Language } from '../enums';
import { LanguageData } from '../models';

export const LANGUAGES: LanguageData[] = [
  {
    code: 'en',
    countryCode: 'us',
    currencySign: '$',
    flagSymbol: '🇺🇸',
    name: 'English',
    nativeName: 'English',
    nativeShortName: 'en',
    locale: 'en-US',
  },
  {
    code: 'de',
    countryCode: 'de',
    currencySign: '€',
    flagSymbol: '🇩🇪',
    name: 'German',
    nativeName: 'Deutsch',
    nativeShortName: 'de',
    locale: 'de-DE',
  },
];

export const LOCALES_MAP: Map<Language, unknown> = new Map([
  [Language.English, localeEn],
  [Language.German, localeDe],
]);

export const KENDO_LOCALES_MAP: Map<Language, unknown> = new Map([
  [Language.English, enKendoLocale],
  [Language.German, deKendoLocale],
]);
