import {CurrencyFormat} from 'app/shared/models/currency-format';

export const CURRENCY_FORMAT: { [code: string]: CurrencyFormat } = {
  USD: {symbol: '$', position: 'left', whitespace: false}, GBP: {symbol: '£', position: 'left', whitespace: false},
  CAD: {symbol: 'Can$', position: 'left', whitespace: false}, EUR: {symbol: '€', position: 'right', whitespace: true},
  JPY: {symbol: '¥', position: 'left', whitespace: false}, AUD: {symbol: 'A$', position: 'left', whitespace: false},
  CHF: {symbol: 'CHF', position: 'left', whitespace: false}, ZAR: {symbol: 'R', position: 'left', whitespace: false},
  SEK: {symbol: 'SKr', position: 'right', whitespace: true}, HKD: {symbol: 'HK$', position: 'left', whitespace: false},
  NZD: {symbol: 'NZ$', position: 'left', whitespace: false}, DKK: {symbol: 'DKr', position: 'left', whitespace: true},
  NOK: {symbol: 'NKr', position: 'left', whitespace: true}, INR: {symbol: '₹', position: 'left', whitespace: false},
  AED: {symbol: 'د.إ', position: 'right', whitespace: true}, RON: {symbol: 'lei', position: 'right', whitespace: true},
  TRY: {symbol: '₺', position: 'right', whitespace: true}, ARS: {symbol: 'Arg$', position: 'left', whitespace: false},
  BRL: {symbol: 'R$', position: 'left', whitespace: false}, SGD: {symbol: 'S$', position: 'left', whitespace: false},
  PLN: {symbol: 'zł', position: 'right', whitespace: true}, ILS: {symbol: '₪', position: 'left', whitespace: false},
  MYR: {symbol: 'RM', position: 'left', whitespace: false}, CZK: {symbol: 'Kč', position: 'right', whitespace: true},
  COP: {symbol: 'Col$', position: 'left', whitespace: false},
  MXN: {symbol: 'Mex$', position: 'left', whitespace: false}, CNY: {symbol: '¥', position: 'left', whitespace: false},
  PHP: {symbol: '₱', position: 'left', whitespace: false}, MMK: {symbol: 'K', position: 'right', whitespace: true},
  OMR: {symbol: 'ر.ع.', position: 'right', whitespace: true}, BGN: {symbol: 'лв', position: 'right', whitespace: false},
  TWD: {symbol: 'NT$', position: 'left', whitespace: false}
};
