<kendo-grid-messages
  [noRecords]="'SHARED.GRID.NO_RECORDS' | translate"
  [loading]="'SHARED.GRID.LOADING' | translate"
  [detailCollapse]="'SHARED.GRID.DETAIL_COLLAPSE' | translate"
  [detailExpand]="'SHARED.GRID.DETAIL_EXPAND' | translate"
  [pagerFirstPage]="'SHARED.GRID.PAGER_FIRST_PAGE' | translate"
  [pagerItemsPerPage]="'SHARED.GRID.PAGER_ITEMS_PER_PAGE' | translate"
  [pagerPage]="'SHARED.GRID.PAGER_PAGE' | translate"
  [pagerOf]="'SHARED.GRID.PAGER_OF' | translate"
  [pagerLastPage]="'SHARED.GRID.PAGER_LAST_PAGE' | translate"
  [pagerNextPage]="'SHARED.GRID.PAGER_NEXT_PAGE' | translate"
  [pagerPreviousPage]="'SHARED.GRID.PAGER_PREVIOUS_PAGE' | translate"
  [sortAscending]="'SHARED.GRID.SORT_ASCENDING' | translate"
  [sortDescending]="'SHARED.GRID.SORT_DESCENDING' | translate"
  [filter]="'SHARED.GRID.FILTER' | translate"
  [groupPanelEmpty]="'SHARED.GRID.GROUP_PANEL_EMPTY' | translate"
  [groupPanelLabel]="'SHARED.GRID.GROUP_PANEL_LABEL' | translate"
  [selectAllCheckboxLabel]="'SHARED.GRID.SELECT_ALL_CHECKBOX_LABEL' | translate"
  [selectionCheckboxLabel]="'SHARED.GRID.SELECTION_CHECKBOX_LABEL' | translate"
  [groupCollapse]="'SHARED.GRID.GROUP_COLLAPSE' | translate"
  [groupExpand]="'SHARED.GRID.GROUP_EXPAND' | translate"
  [autosizeAllColumns]="'SHARED.GRID.AUTOSIZE_ALL_COLUMNS' | translate"
  [autosizeThisColumn]="'SHARED.GRID.AUTOSIZE_THIS_COLUMN' | translate"
  [booleanFilterCellLabel]="'SHARED.GRID.BOOLEANFILTERCELLLABEL' | translate"
  [columns]="'SHARED.GRID.COLUMNS' | translate"
  [columnsApply]="'SHARED.GRID.COLUMNS_APPLY' | translate"
  [columnsReset]="'SHARED.GRID.COLUMNS_RESET' | translate"
  [dragRowHandleLabel]="'SHARED.GRID.DRAG_ROW_HANDLE_LABEL' | translate"
  [filterAfterOperator]="'SHARED.GRID.FILTER_AFTER_OPERATOR' | translate"
  [filterAfterOrEqualOperator]="'SHARED.GRID.FILTER_AFTER_OR_EQUAL_OPERATOR' | translate"
  [filterAndLogic]="'SHARED.GRID.FILTER_AND_LOGIC' | translate"
  [filterBeforeOperator]="'SHARED.GRID.FILTER_BEFORE_OPERATOR' | translate"
  [filterBeforeOrEqualOperator]="'SHARED.GRID.FILTER_BEFORE_OR_EQUAL_OPERATOR' | translate"
  [filterBooleanAll]="'SHARED.GRID.FILTER_BOOLEAN_ALL' | translate"
  [filterCellOperatorLabel]="'SHARED.GRID.FILTER_CELL_OPERATOR_LABEL' | translate"
  [filterClearButton]="'SHARED.GRID.FILTER_CLEAR_BUTTON' | translate"
  [filterDateToday]="'SHARED.GRID.FILTER_DATE_TODAY' | translate"
  [filterDateToggle]="'SHARED.GRID.FILTER_DATE_TOGGLE' | translate"
  [filterEndsWithOperator]="'SHARED.GRID.FILTER_ENDS_WITH_OPERATOR' | translate"
  [filterEqOperator]="'SHARED.GRID.FILTER_EQ_OPERATOR' | translate"
  [filterGteOperator]="'SHARED.GRID.FILTER_GTE_OPERATOR' | translate"
  [filterGtOperator]="'SHARED.GRID.FILTER_GT_OPERATOR' | translate"
  [filterInputLabel]="'SHARED.GRID.FILTER_INPUT_LABEL' | translate"
  [filterIsEmptyOperator]="'SHARED.GRID.FILTER_IS_EMPTY_OPERATOR' | translate"
  [filterIsFalse]="'SHARED.GRID.FILTER_IS_FALSE' | translate"
  [filterIsNotEmptyOperator]="'SHARED.GRID.FILTER_IS_NOT_EMPTY_OPERATOR' | translate"
  [filterIsNotNullOperator]="'SHARED.GRID.FILTER_IS_NOT_NULL_OPERATOR' | translate"
  [filterIsNullOperator]="'SHARED.GRID.FILTER_IS_NULL_OPERATOR' | translate"
  [filterIsTrue]="'SHARED.GRID.FILTER_IS_TRUE' | translate"
  [filterLteOperator]="'SHARED.GRID.FILTER_LTE_OPERATOR' | translate"
  [filterLtOperator]="'SHARED.GRID.FILTER_LT_OPERATOR' | translate"
  [filterMenuTitle]="'SHARED.GRID.FILTER_MENU_TITLE' | translate"
  [filterNotContainsOperator]="'SHARED.GRID.FILTER_NOT_CONTAINS_OPERATOR' | translate"
  [filterNotEqOperator]="'SHARED.GRID.FILTER_NOT_EQ_OPERATOR' | translate"
  [filterNumericDecrement]="'SHARED.GRID.FILTER_NUMERIC_DECREMENT' | translate"
  [filterNumericIncrement]="'SHARED.GRID.FILTER_NUMERIC_INCREMENT' | translate"
  [filterOrLogic]="'SHARED.GRID.FILTER_OR_LOGIC' | translate"
  [lock]="'SHARED.GRID.LOCK' | translate"
  [unlock]="'SHARED.GRID.UNLOCK' | translate"
  [sortable]="'SHARED.GRID.SORTABLE' | translate"
  [groupChipMenuNext]="'SHARED.GRID.GROUP_CHIP_MENU_NEXT' | translate"
  [groupChipMenuPrevious]="'SHARED.GRID.GROUP_CHIP_MENU_PREVIOUS' | translate"
></kendo-grid-messages>
