export class Color {
  /** Whether the dark contrast is used or not. */
  public darkContrast: boolean;
  /** Define hex value of a color */
  public hex: string;
  /** Define the name of color to set the theme variable */
  public name: string;

  constructor() {
    this.name         = '';
    this.hex          = '';
    this.darkContrast = false;
  }
}
