import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorCacheService {
  private cachePrefix            = 'errorCache';
  // 10 minutes throttle time
  private readonly THROTTLE_TIME = 10 * 60 * 1000;

  setCache(key: string): void {
    const now        = new Date().getTime();
    const cacheValue = {timestamp: now};
    localStorage.setItem(`${this.cachePrefix}-${key}`, JSON.stringify(cacheValue));
  }

  getCache(key: string): number | null {
    const cacheItem = localStorage.getItem(`${this.cachePrefix}-${key}`);
    if (cacheItem) {
      const cacheValue = JSON.parse(cacheItem);
      const now        = new Date().getTime();

      if (now - cacheValue.timestamp < this.THROTTLE_TIME) {
        return cacheValue.timestamp;
      } else {
        this.clearCache(key);
      }
    }
    return null;
  }

  clearCache(key: string): void {
    localStorage.removeItem(`${this.cachePrefix}-${key}`);
  }
}
