export const cognito = {
  selfserveportalLambdaRestApiEndpoint37915857:
    'https://portal.si.trilogy.com/api/',
  userPoolClientId: '6v8065bqiphspjlok81kpvur10',
  usersImportCsvS3:
    'self-serve-portal-prod-userscsvimporta06ab4b2-pti7i4ly3hud',
  portalBackendEndpoint: 'https://portal.si.trilogy.com/api/',
  userPoolId: 'us-east-1_6C4T4qDOU',
  spaDeploycloudfrontdomain618AA1F7: 'd2gqu1aksku5d9.cloudfront.net',
};
