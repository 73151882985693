import { Component } from '@angular/core';
import { BUService } from './core/services/bu-service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  constructor(private readonly buService: BUService) {
    this.buService.getBUUnit().pipe(take(1)).subscribe();
  }
}
