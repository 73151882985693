import {PaginationOptions} from 'src/app/shared/models/pagination-options';

export const DEFAULT_PRIMARY_COLOR           = '#0465BF';
export const DEFAULT_SECONDARY_COLOR         = '#00BBD7';
export const BLACK_COLOR                     = 'rgba(black, 0.87)';
export const WHITE_COLOR                     = '#FFFFFF';
export const DEFAULT_PAGE_SIZE               = 10;
export const PAGE_OPTIONS: PaginationOptions = {
  pageSize:             DEFAULT_PAGE_SIZE,
  showFirstLastButtons: true,
  pageIndex:            0,
  length:               0,
  pageSizeOptions:      [5, 10, 20, 50]
};
