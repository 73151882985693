import {JsonProperty} from 'json-object-mapper';
import {DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR} from 'src/app/core/config/app.config';

export class Payload {
  @JsonProperty() public primaryColor: string;
  @JsonProperty() public secondaryColor: string;
  @JsonProperty({name: 'jwt'}) public token: string;

  constructor() {
    this.primaryColor   = DEFAULT_PRIMARY_COLOR;
    this.secondaryColor = DEFAULT_SECONDARY_COLOR;
    this.token          = '';
  }
}
