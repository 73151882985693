import {HttpBackend, HttpClient, HttpInterceptor} from '@angular/common/http';
import {Injectable, InjectionToken, Injector} from '@angular/core';
import {InterceptorsHandler} from 'src/app/core/http/common/interceptors.handler';

export const AUTH_HTTP_INTERCEPTORS: InjectionToken<HttpInterceptor[]> = new InjectionToken<HttpInterceptor[]>(
  'AUTH_HTTP_INTERCEPTORS'
);

/**
 * Extend Http Client to use custom interceptor for cloning a request with authorization header before sending a
 * backend call.
 */
@Injectable()
export class AuthHttpClient extends HttpClient {
  constructor(backend: HttpBackend, injector: Injector) {
    super(new InterceptorsHandler(backend, injector, AUTH_HTTP_INTERCEPTORS));
  }
}
