import {Injectable} from '@angular/core';
import {ObjectMapper} from 'json-object-mapper';
import jwt_decode from 'jwt-decode';

import {BehaviorSubject, Observable, of} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {Payload} from 'src/app/core/models/payload.model';
import {ThemeGenerator} from 'src/app/core/utilities/theme-generator';

@Injectable({providedIn: 'root'})
export class InitializationService {
  /** Observable returning all the query params as payload */
  private readonly queryParams = new BehaviorSubject<Payload>(null);
  public queryParams$          = this.queryParams.asObservable().pipe(distinctUntilChanged());

  /** Return JWT token received as query param on load of application */
  public get token(): string {
    return this.queryParams?.value?.token;
  }

  public get tokenCustomerId(): string {
    const {cid} = jwt_decode<{ cid: string }>(this.token);
    return cid;
  }

  /**
   * Fetch the query params from url on load of applicaton.
   * For Example:
   * From URL: http://localhost:4200/?jwt=DNNfsdfsdfsd
   * We can get all the query params and can use those params in our application.
   * Purpose of extracting the query params is that we want to use the application as iframe where we can send the
   * query params as input for the whole applicaiton.
   */
  public getQueryParams(): Observable<boolean> {
    const params: URLSearchParams                      = new URLSearchParams(location.search);
    const paramObj: { [key: string]: string | number } = {};

    for (const param of params.keys()) {
      paramObj[param] = params.get(param);
    }

    const queryParams = ObjectMapper.deserialize(Payload, paramObj);
    this.queryParams.next(queryParams);

    ThemeGenerator.generateTheme(queryParams.primaryColor, queryParams.secondaryColor);
    // Returning a mock observable for now.
    // But we can use the endpoint to be called here so that we can fetch the data using the payload generated from
    // query params.
    return of(true).pipe(distinctUntilChanged());
  }
}
