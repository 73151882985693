<div class="language-switcher-container">
  <!-- Language dropdown with flag emojis -->
  <kendo-dropdownlist
    [data]="languages"
    [value]="selectedLanguage"
    (valueChange)="switchLanguage($event.value)"
    textField="text"
    valueField="value"
    class="language-dropdown">

    <!-- Template for displaying flag with the current selected value -->
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span [ngClass]="getLanguageFlagClass(dataItem.value)"></span>
      {{ dataItem.text }}
    </ng-template>

    <!-- Template for displaying flag with each dropdown item -->
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <span [ngClass]="getLanguageFlagClass(dataItem.value)"></span>
      {{ dataItem.text }}
    </ng-template>
  </kendo-dropdownlist>
</div>
