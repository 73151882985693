import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs/internal/Observable';

/**
 * Call the given Http interceptor over the request.
 */
export class InterceptHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {}

  /** Custom interceptor for HTTP Handler. */
  public handle<T>(req: HttpRequest<T>): Observable<HttpEvent<T>> {
    return this.interceptor.intercept(req, this.next);
  }
}
