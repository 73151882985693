import {ObjectMapper} from 'json-object-mapper';
import {BLACK_COLOR, WHITE_COLOR} from 'src/app/core/config/app.config';
import {COLOR_CONFIG} from 'src/app/core/config/color.config';
import {ColorType} from 'src/app/core/enums/color-type.enum';
import {Color} from 'src/app/core/models/color.model';

declare const tinycolor: any;

export class ThemeGenerator {
  private static primaryColor: string;
  private static secondaryColor: string;

  public static generateTheme(primaryColor: string, secondaryColor: string): void {
    this.primaryColor   = primaryColor;
    this.secondaryColor = secondaryColor;
    this.setColor(ColorType.PRIMARY, this.computeColors(this.primaryColor));
    this.setColor(ColorType.SECONDARY, this.computeColors(this.secondaryColor));
  }

  private static computeColors(hex: string): Color[] {
    return COLOR_CONFIG.map(item => this.getColorObject(
      item.value ? tinycolor(hex).lighten(item.value) : tinycolor(hex),
      item.name
      )
    );
  }

  private static getColorObject(value: number | string, name: string): Color {
    const color = tinycolor(value);

    return ObjectMapper.deserialize(Color, {
      name,
      hex:          color.toHexString(),
      darkContrast: color.isLight()
    });
  }

  private static setColor(type: ColorType, colorPalette: Color[]): void {
    for (const color of colorPalette) {
      const key1   = `--theme-${type}-${color.name}`;
      const value1 = color.hex;
      const key2   = `--theme-${type}-contrast-${color.name}`;
      const value2 = color.darkContrast ? BLACK_COLOR : WHITE_COLOR;
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }
}
