import {ColorConfig} from 'src/app/core/models/color-config.model';

const colorName1  = '50';
const colorValue1 = 52;

const colorName2  = '37';
const colorValue2 = 100;

const colorName3  = '200';
const colorValue3 = 26;

const colorName4  = '300';
const colorValue4 = 12;

const colorName5  = '400';
const colorValue5 = 6;

const colorName6 = '500';

const colorName7  = '600';
const colorValue7 = 6;

const colorName8  = '700';
const colorValue8 = 12;

const colorName9  = '800';
const colorValue9 = 18;

const colorName10  = '900';
const colorValue10 = 24;

const colorName11     = 'A100';
const colorValue11    = 50;
const colorSaturate11 = 30;

const colorName12     = 'A200';
const colorValue12    = 30;
const colorSaturate12 = 30;

const colorName13     = 'A400';
const colorValue13    = 10;
const colorSaturate13 = 15;

const colorName14     = 'A700';
const colorValue14    = 5;
const colorSaturate14 = 5;

export const COLOR_CONFIG: ColorConfig[] = [
  {
    name:  colorName1,
    value: colorValue1
  },
  {
    name:  colorName2,
    value: colorValue2
  }, {
    name:  colorName3,
    value: colorValue3
  }, {
    name:  colorName4,
    value: colorValue4
  }, {
    name:  colorName5,
    value: colorValue5
  }, {
    name: colorName6
  }, {
    name:  colorName7,
    value: colorValue7
  }, {
    name:  colorName8,
    value: colorValue8
  }, {
    name:  colorName9,
    value: colorValue9
  }, {
    name:  colorName10,
    value: colorValue10
  }, {
    name:            colorName11,
    value:           colorValue11,
    saturationValue: colorSaturate11
  }, {
    name:            colorName12,
    value:           colorValue12,
    saturationValue: colorSaturate12
  }, {
    name:            colorName13,
    value:           colorValue13,
    saturationValue: colorSaturate13
  }, {
    name:            colorName14,
    value:           colorValue14,
    saturationValue: colorSaturate14
  }
];
