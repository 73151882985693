import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform, OnDestroy {
  private locale: string;
  private subscription: Subscription;

  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService, private datePipe: DatePipe) {
    this.locale = this.translateService.currentLang || this.translateService.getDefaultLang();

    this.subscription = this.translateService.onLangChange.pipe(takeUntil(this.destroy$))
      .subscribe((event: LangChangeEvent) => {
        this.locale = event.lang;
        this.cdr.markForCheck();
      });
  }

  transform(value: any, format: string = 'mediumDate'): string | null {
    return this.datePipe.transform(value, format, undefined, this.locale);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}