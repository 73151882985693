import { Injectable } from '@angular/core';
import { PortalAuthService } from 'src/app/core/services/portal-auth.service';

@Injectable({ providedIn: 'root' })
export class PortalAuthFacade {
  /** Verify if the logged in user session is valid */
  public isLoggedIn$ = this.service.isLoggedIn$;
  /** Whether or not there is any error thrown by cognito while logging in */
  public loginError$ = this.service.loginError$;
  /** Whether or not there is any error while retrieving the session details. */
  public loginSessionError$ = this.service.loginSessionError$;
  /** To be used to login the users. */
  public signIn = this.service.signIn.bind(this.service);
  public signUp = this.service.signUp.bind(this.service);
  public isTokenExpired = this.service.isTokenExpired.bind(this.service);
  public setLoading = this.service.setLoading.bind(this.service);
  public getUserProperties = this.service.getUserProperties.bind(this.service);
  /** Represents the user session details containing the access token. */
  public token$ = this.service.token$;
  /** Represents the user session details containing the access token. */
  public userSession$ = this.service.userSession$;
  /** if submitted */
  public submitted$ = this.service.submitted$;
  public loading$ = this.service.loading$;

  public signOut = this.service.signOut.bind(this.service);

  constructor(private readonly service: PortalAuthService) {}
}
